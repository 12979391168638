import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
const IndexPage = ({ data }) => (
  <Layout >
    <SEO title="Home" />
    <Holder>
      <BackgroundImage
        fluid={data.file.childImageSharp.fluid}
      ><Section><div><h1>Hello!</h1><p>I'm Noelia an illustrator and graphic designer</p></div></Section></BackgroundImage></Holder>
  </Layout>
)

export default IndexPage
export const query = graphql`
 {
  file(relativePath: {eq: "back.jpg"}){
    childImageSharp{
      fluid{
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}

`
const Section = styled.div`
  width: 100%;
 position: relative;
 color: white;
   box-sizing: border-box;  h1{

font-size:3rem;
  }
  div{
    position: absolute;
    bottom:80px ;
    left:40px;
  }
  p{
  
    font-size:2rem;
  }
  @media(max-width: 700px){
    height: 90.7vh;
  }
  @media(min-width: 700px){
    height: 100vh;
  }
`
const Holder = styled.section`
 overflow: hidden !important;
 `